<template>
<!-- main-area -->
<section class="main-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <side-bar />
                <!-- right-body -->
                <div class="audits-completed col-md-12">
                    <the-header/>
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- main-area -->
</template>

<script>
//import TheFooter from './../common/TheFooter'
import TheHeader from './../common/DashboardHeader'
import SideBar from './../common/DashboardSidebar'
import axios from 'axios'
import Echo from 'laravel-echo';
import 'bootstrap/dist/css/bootstrap.min.css'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
export default {
    name: 'DashboardContainer', 
    components: {
        //TheFooter,
        TheHeader,
        SideBar
    },
    created(){
        axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$storage.getStorageSync('token')
        // console.log(this.$storage.getStorageSync('token'))

        window.Pusher = require('pusher-js');

        window.Echo = new Echo({
            authEndpoint: process.env.VUE_APP_BASE_URL+'broadcasting/auth', 
            broadcaster: process.env.VUE_APP_BROADCAST_DRIVER,
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPT,
            wsHost: window.location.hostname,
            wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
            wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
            forceTLS: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: 'Bearer ' + this.$storage.getStorageSync('token')
                },
            },
        });
    }
}
</script>
<style>
@import './../../assets/css/dashboard/bootstrap.min.css';
@import './../../assets/css/dashboard/style.css';
@import './../../assets/css/dashboard/responsive.css';

</style>