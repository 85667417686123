<template>
  <div class="header-area">
    <profile-completetion />
    <div class="row">
      <!-- title -->
      <div class="col-lg-6">
        <div class="title">
          <h2>
            <span class="mob" v-on:click="toggleSidebar('mob')">
              <img class="img-fluid ms-2" src="@/assets/images/dashboard/menu.png" style="width:16px" alt=""/>
            </span>
            <span class="menuwidth" v-on:click="toggleSidebar('web')">
              <img class="img-fluid ms-2 mt-2" src="@/assets/images/dashboard/menu.png" alt="" style="width:16px" />
            </span> {{ this.$route.meta.pageTitle }}
            <span v-if="$storage.getStorageSync('business').active_business.title"
              style="
                font-size: 13px;
                margin-left: 10px;
                margin-top: 10px;
                cursor: pointer;
              "
              data-bs-toggle="modal"
              data-bs-target="#chooseBusinessModal"
              >{{ $storage.getStorageSync("business").active_business.title
              }}<fa icon="pencil-alt" class="ms-1" style="font-size: 11px"
            /></span>
          </h2>
        </div>
      </div>
      <!-- title -->
      <!-- notification -->
      <div class="col-lg-6">
        <div class="notification">
          <ul>
            <!-- <li>
              <a class="bells" href="#" v-on:click="toggleTab('MsgTab')" >
                <span><Tooltip :tooltipText="'Messages'" position="bottom" style="font-size:16px"><img class="img-fluid" src="@/assets/images/dashboard/msg.svg" alt="" /></Tooltip></span>
                <span>2</span>
              </a>
              <div class="notif-tabs" id="MsgTab">
                <div class="tab-content">
                  <div class="tab-pane fade show active">
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <a class="bells" href="#" v-on:click="toggleTab('NotiTab')">
                <img
                  class="img-fluid"
                  src="@/assets/images/dashboard/Bell.svg"
                  alt=""
                />
                <span>9+</span>
                </a>
              <div class="notif-tabs" id="NotiTab">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      >Fresh</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link border-right-0"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      >Merged</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                    <div class="notif-irem">
                      <div class="noti-title">
                        <h3>javin a. lumpkin</h3>
                        <p>internet led</p>
                      </div>
                      <div class="noti-action">
                        <a href="#">untouched</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li> -->
            <li class="walkBtn d-sm-block d-none" v-if="this.user_role == 'business'">
              <router-link :to="{ name: 'addWalkin' }" class="lead-btn">
                Add Walk-In <fa icon="plus" />
              </router-link>
            </li>
            <li class="walkBtn d-sm-none" v-if="this.user_role == 'business'">
              <router-link :to="{ name: 'addWalkin' }" class="lead-btn"><fa icon="plus" /></router-link>
            </li>
            <!-- <li v-if="$storage.getStorageSync('token') && this.user_role == 'business' " class="noti-dropdown">
                <button type="button" class="position-relative border-0 bg-transparent mt-3" data-bs-toggle="dropdown" aria-expanded="false">
                     <Tooltip :tooltipText="'Messages'" position="bottom" style="font-size:15px"><fa :icon="['far','envelope']" class="fs-5 m-0" /></Tooltip>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">9+</span>
                </button>
                <div class="dropdown-menu p-0">
                    <div class="list-group">
                        <div class="d-flex align-items-center p-3 border-bottom">
                            <div class="col">
                                <h6 class="m-0 text-muted fw-bold"><fa :icon="['far','envelope']" class="fs-6 me-1" /> Inbox &nbsp;<span>(0)</span> </h6>
                            </div>
                            <div class="col-auto">
                                <a href="#!" class="small fw-bold"> View All</a>
                            </div>
                        </div>
                        <div class="noti_div">
                            <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a">
                                <div class="d-flex">
                                    <div class="avatar-xs m-auto">
                                        <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-0">desiretyree</h6>
                                        <div class="font-size-12">
                                            <p class="lh-1 text-muted">Okay than you</p>
                                            <p class="mb-0 text-black-50">3 min</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a unread_noti">
                                <div class="d-flex">
                                    <div class="avatar-xs m-auto">
                                        <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-0">my-beauty-saloon</h6>
                                        <div class="font-size-12">
                                            <p class="lh-1 text-muted">got it. for that you need to provide me access to you application or if you want..</p>
                                            <p class="mb-0 text-black-50">yesterday</p>
                                        </div>
                                    </div>
                                    <div class="flex-grow-2 me-2 mt-1">
                                        <fa :icon="['far','envelope']" class="fs-4 text-muted" />
                                    </div>
                                </div>
                            </a>
                            <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a">
                                <div class="d-flex">
                                    <div class="avatar-xs m-auto">
                                        <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-0">absalon</h6>
                                        <div class="font-size-12">
                                            <p class="lh-1 text-muted">No problem. Thank you!</p>
                                            <p class="mb-0 text-black-50">4h</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a unread_noti">
                                <div class="d-flex">
                                    <div class="avatar-xs m-auto">
                                        <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-0">bilalians-studio</h6>
                                        <div class="font-size-12">
                                            <p class="lh-1 text-muted">Sent the new version</p>
                                            <p class="mb-0 text-black-50">2 month</p>
                                        </div>
                                    </div>
                                    <div class="flex-grow-2 me-2 mt-1">
                                        <fa :icon="['far','envelope']" class="fs-4 text-muted" />
                                    </div>
                                </div>
                            </a>
                            <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a">
                                <div class="d-flex">
                                    <div class="avatar-xs m-auto">
                                        <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-0">acevision</h6>
                                        <div class="font-size-12">
                                            <p class="lh-1 text-muted">how you felt about your recent delivery.</p>
                                            <p class="mb-0 text-black-50">1 week</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-2 border-top">
                        </div>
                    </div>
                </div>
            </li> -->
            <li v-if="this.user_role == 'business' ">
              <MessageNotification id="dashboardNotificaions" />
            </li>
            <li>
                <Notification id="dashboardNotificaions" />
            </li>


            <li class="acvive-user dropdown navbar mt-2 ms-4">
              <a
                class="user-logout dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  v-if="$storage.getStorageSync('user').picture"
                  class="img-fluid"
                  :src="$storage.getStorageSync('user').picture"
                  alt=""
                />
                <img
                  v-else
                  class="img-fluid"
                  :src="assetUrl('profile/profile.svg')"
                  alt=""
                />
                <span class="user-active"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item atve" href="#">Active</a>
                <a class="dropdown-item Away" href="#">Away</a>
                <a class="dropdown-item" href="#" v-on:click="logout">Logout</a>
              </div>
            </li>
            
            
          </ul>
        </div>
      </div>
      <!-- notification -->
    </div>

    <!-- Business Access Modal -->
    <div
      class="modal fade"
      id="chooseBusinessModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="chooseBusinessModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="chooseBusinessModalLabel">
              Switch Business
            </h5>
            <a class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/images/icons/Closex.svg"
                  alt="Close"
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateSelectedBusiness" autocomplete="off">
            <div class="modal-body">
              <div class="add-member add-lead py-2">
                <div class="form-group">
                  <label>Business</label>
                  <select class="form-control" v-model="selected_business">
                    <option value="">Choose Business</option>
                    <option
                      v-for="business in $storage.getStorageSync('business')
                        .list"
                      :value="business"
                      :key="business.id"
                    >
                      {{ business.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="add-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              <button type="submit" class="rem-btn">Change</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Business Access Modal -->
  </div>
</template>

<script>
// import ProfileCompletetion from "./../common/profile-completetion";
import $ from "jquery";
import axios from "axios";
import ProfileCompletetion from "./../common/ProfileCompletetion.vue";
// import Tooltip from "@/components/common/Tooltip.vue"
import Notification from "@/components/notifications/Notification"
import MessageNotification from "@/components/notifications/Messages"
import { useAuth } from '@/composables/Auth';

export default {
  components: {
    ProfileCompletetion,
    // Tooltip,
    Notification,
    MessageNotification
  },
  setup() {
      const { logout } = useAuth();
      return {logout}
  },
  data() {
    return {
      selected_business: "",
      user_role: "",
      employee_id: 0
      //businesses:[]
    };
  },
  mounted() {
        this.user_role = this.$storage.getStorageSync('role')
        this.employee_id = this.$storage.getStorageSync('employee_id')
    },
  created() {
    this.toggleSidebar("web");
    this.selected_business =
      this.$storage.getStorageSync("business").active_business;

    $("body").mouseup(function (e) {
      var container = $(".notif-tabs,.bells");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".notif-tabs").removeClass("opens");
      }
    });
    //this.getAssignedBusiness()
  },
  methods: {
    updateSelectedBusiness() {
      if (this.selected_business != undefined && this.selected_business != "") {
        let business_data = this.$storage.getStorageSync("business");
        business_data.active_business = this.selected_business;
        setTimeout(() => {
          this.$storage.setStorageSync("business", business_data);
          location.reload(true);
        }, 500);
      }
    },
    getAssignedBusiness() {
      let thiss = this;
      axios
        .get("assigned_businesses")
        .then((response) => {
          if (response.data.status == "success") {
            let business_data = thiss.$storage.getStorageSync("business");
            business_data.list = response.data.data;
            thiss.$storage.setStorageSync("business", business_data);
            //thiss.businesses = response.data.data;
            //console.log("Called",response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              console.log(error.response.data.message);
            }
          }
        });
    },
    toggleTab(id) {
      $(".notif-tabs").removeClass("opens");
      $("#" + id).toggleClass("opens");
    },
    toggleSidebar(where) {
      if (where == "web") {
        $(".menu-area").toggleClass("menu-full");
        $(".hide-item").toggleClass("show-item");
        $(".big-logo").toggleClass("show-logo");
        $(".audits-completed").toggleClass("menu-full-body");
      } else if (where == "mob") {
        $(".menu-area").toggleClass("mobile");
        $(".audits-completed").removeClass("menu-full-body");
        $(".menu-area").removeClass("menu-full");
        $(".hide-item").removeClass("show-item");
        $(".big-logo").removeClass("show-logo");
      }
    },
    // toggleHeaderSearchIcon() {
    //     this.$refs.searchbox.focus();
    //     $(".search-box").toggleClass("full-search-box");
    //     $(".search-box .form-control").toggleClass("full-search-control");
    // }
  },
};
</script>
<style>
.notification .list-group-item{
    border-width: 0px 0px 1px 0px;
}
</style>
<style scoped>
  .dropdown-menu{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
  }

  .notification .walkBtn a{
    background: black ;
    color: #fff;
  }
  .menu-sidebar {
    transition: none !important;
  }
  .vti__dropdown-list{
    width: 221px !important;
    overflow: hidden;
  }
  .vti__dropdown-item{
    padding: 4px 7px !important;
  }
  .vue-tel-input{
    font-size: 0.9375rem;
    line-height: 1.6;
    color: #605e7e;
    height: 39px;
    background-clip: padding-box;
    box-shadow: none;
    border: 1px solid #e8ebf3;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  

  .notification ul li{
    margin-bottom: 0px;
    margin-top: 6px;
  }
  .notification ul li a.lead-btn{
    box-shadow: none;
  }
  @media (max-width: 767px){
  .notification {
      text-align: right;
      float: right;
    }
  }
  @media screen and (max-width: 576px) {
    .notification ul li a.lead-btn{
      padding: 8px 10px;
      margin-top: 6px;
    }
    .notification ul li a.lead-btn svg {
      margin-top: 0px;
      margin-left: 0px;
    }
  }

</style>
