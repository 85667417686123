<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade res"
      id="profileCompletetionModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="row">
            <div class="col-3 left-side">
              <div class="side px-4 py-3 bg-light">
                <img class="py-3 img-fluid" src="./../../assets/images/dashboard_pic/logo.png"/>
                <h4 class="pb-3">Complete Your Profile</h4>
                <div>
                  <span class="circle" v-if="stepNo == 1" v-bind:class="stepNo < 2 ? 'bg-dark' : ''" >1</span>
                  <span class="me-2 ms-2" v-if="stepNo > 1">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="ps-1 font-18 step1">Business Profile</span>
                </div>
                <div class="divHeight"></div>
                <div>
                  <span class="circle" v-if="stepNo < 3" v-bind:class="stepNo == 2 ? 'bg-dark' : ''" >2</span>
                  <span class="me-2 ms-2" v-if="stepNo > 2">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <i class="fas fa-check-circle"></i
                  ><span class="ps-2 font-18">Add Schedule</span>
                </div>
                <div class="divHeight"></div>
                <div>
                  <span class="circle"  v-if="stepNo < 4"  v-bind:class="stepNo == 3 ? 'bg-dark' : ''" >3</span>
                  <span class="me-2 ms-2" v-if="stepNo > 3">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="ps-2 font-18">Services</span>
                  <i class="fas fa-check-circle"></i>
                  <div class="divHeight"></div>
                </div>
                <div>
                  <span class="circle" v-if="stepNo < 5" v-bind:class="stepNo == 4 ? 'bg-dark' : ''" >4</span>
                  <span class="me-2 ms-2" v-if="stepNo > 4">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="ps-2 font-18">Add Employee</span>
                  <i class="fas fa-check-circle"></i>
                  <div class="divHeight"></div>
                </div>
                <div class="mb-4">
                  <span class="circle" v-if="stepNo < 6" v-bind:class="stepNo == 5 ? 'bg-dark' : ''" >5</span>
                  <span class="me-2 ms-2" v-if="stepNo > 5">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="ps-2 font-18">Stripe</span>
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-xl-9 profile-completion-content">
              <div class="row">
                <div class="col-12 horiMultiStep">
                  <h4 class="title mt-4">Complete Your Profile</h4>
                  <ul>
                    <li class="lineResponsive">
                      <span class="circle" v-if="stepNo == 1" v-bind:class="stepNo < 2 ? 'bg-dark' : ''">1</span>
                      <span v-if="stepNo > 1">
                        <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                      </span>
                      <span class="makeLine"></span>
                      <div class="font-18 mt-1">Business Profile</div>
                    </li>
                    <li class="lineResponsive">
                      <span class="circle" v-if="stepNo < 3" v-bind:class="stepNo == 2 ? 'bg-dark' : ''"> 2 </span>
                      <span v-if="stepNo > 2">
                        <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                      </span>
                      <span class="makeLine"></span>
                      <div class="font-18 mt-1">Add Schedule</div>
                    </li>
                    <li class="lineResponsive">
                      <span class="circle" v-if="stepNo < 4" v-bind:class="stepNo == 3 ? 'bg-dark' : ''">3</span>
                      <span v-if="stepNo > 3">
                        <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                      </span>
                      <span class="makeLine"></span>
                      <div class="font-18 mt-1">Add Services</div>
                    </li>
                    <li class="lineResponsive">
                      <span class="circle" v-if="stepNo < 5" v-bind:class="stepNo == 4 ? 'bg-dark' : ''">4</span>
                      <span v-if="stepNo > 4">
                        <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                      </span>
                      <span class="makeLine"></span>
                      <div class="font-18 mt-1">Add Employee</div>
                      <i class="fas fa-check-circle"></i>
                    </li>
                    <li style="text-align: center; min-width: 65px">
                      <span class="circle" v-if="stepNo < 6" v-bind:class="stepNo == 5 ? 'bg-dark' : ''">5</span>
                      <span v-if="stepNo > 5">
                        <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                      </span>
                      <div class="font-18 mt-1">Stripe</div>
                      <i class="fas fa-check-circle"></i>
                    </li>
                  </ul>

                </div>
              </div>
              <div class="row" v-if="stepNo == 1">
                <div class="col-12">
                    <h1 class="px-3 pt-3">Business Profile</h1>
                </div>
                <div class="col-12 text-center">
                    <img class="img-fluid" src="./../../assets/images/business_profile/business_profile.png"/>
                </div>
                <div class="col-12 text-center mb-1">
                  <p>
                    Carefully fill out your business details, <br>schedules and manage your future dates.
                  </p>
                </div>
                <div class="col-12 text-center">
                  <router-link :to="{name:'editBusinessProfile'}" class="rem-btn mx-auto mb-5" @click="gotoPage()" >Go to business profile</router-link>
                </div>
              </div>

               <div class="row" v-if="stepNo == 2">
                <div class="col-12">
                    <h1 class="px-3 pt-3">Business Schedule</h1>
                </div>
                <div class="col-12 text-center">
                    <!-- <img class="img-fluid" src="./../../assets/images/business_profile/Team.svg"/> -->
                    <img class="img-fluid" src="./../../assets/images/business_profile/business_profile.png"/>
                </div>
                <div class="col-12 text-center mb-1">
                  <p>
                    This is the most important feature that controls your business, take your time and choose the time you open and close.
                  </p>
                </div>
                <div class="col-12 text-center">
                  <router-link :to="{name:'weeklySchedule'}" class="rem-btn mx-auto mb-5" @click="gotoPage()" >Go to business schedule</router-link>
                </div>
              </div>

              <div class="row" v-if="stepNo == 4">
                <div class="col-12">
                    <h1 class="px-3 pt-3">Add Employee</h1>
                </div>
                <div class="col-12 text-center">
                    <!-- <img class="img-fluid" src="./../../assets/images/business_profile/Team.svg"/> -->
                    <img class="img-fluid" src="./../../assets/images/business_profile/business_profile.png"/>
                </div>
                <div class="col-12 text-center mb-1">
                  <p>
                    Manage Team
                    Add your team members to your business account,
                    without this process, services cannot be assigned.
                    -	First, you should search with their email
                    -	If the email does not exist, you may add the professional
                    -	Fill out the form and assign services to the professional
                  </p>
                </div>
                <div class="col-12 text-center">
                  <router-link :to="{name:'addEmployee'}" class="rem-btn mx-auto mb-5" @click="gotoPage()" >Go to add employee</router-link>
                </div>
              </div>

              <div class="row" v-if="stepNo == 3">
                <div class="col-12">
                    <h1 class="px-3 pt-3">Add Services</h1>
                </div>
                <div class="col-12 text-center">
                    <!-- <img class="img-fluid" src="./../../assets/images/business_profile/Service.svg"/> -->
                    <img class="img-fluid" src="./../../assets/images/business_profile/business_profile.png"/>
                </div>
                <div class="col-12 text-center mb-1">
                  <p>
                    Services <br>
                    You may choose a service from the dropdown list or add a new service.<br>
                    Note: When adding a new service, choose the appropriate categories from the list provided
                  </p>
                </div>
                <div class="col-12 text-center">
                  <router-link :to="{name:'addService'}" class="rem-btn mx-auto mb-5" @click="gotoPage()" >Go to services</router-link>
                </div>
              </div>

              <div class="row" v-if="stepNo == 5">
                <div class="col-12">
                    <h1 class="px-3 pt-3">Stripe</h1>
                </div>
                <div class="col-12 text-center">
                    <!-- <img class="img-fluid" src="./../../assets/images/business_profile/Stripe_dollar2.svg"/> -->
                    <img class="img-fluid" src="./../../assets/images/business_profile/business_profile.png"/>
                </div>
                <div class="col-12 text-center mb-1">
                  <p>
                    Secured and fast transactions, follow the process by filling up the form, if you have an existing account, you will be asked to enter the details.
                  </p>
                </div>
                <div class="col-12 text-center">
                  <router-link :to="{name:'stripeConnect'}" class="rem-btn mx-auto mb-5" @click="gotoPage()" >Go to stripe</router-link>
                </div>
              </div>

              <!-- <div class="p2" v-if="stepNo == 2">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Add Employee</h1>
                  <p class="pra">
                    The verification status will update automatically. We are
                    currently checking your data The verification status will
                    update automatically. Before candidates settle into your ad,
                    they’re first going to scan it. And if it’s not formatted
                    using big, bold, clear, and concise subheads to make the
                    scanning process effortless, they’ll move on.
                  </p>
                  <button
                    type="button"
                    class="btn btn-style btn-hori"
                    v-on:click="gotoPage('Employees')"
                  >
                    Go to Employee
                  </button>
                </div>
              </div>
              <div class="p3" v-if="stepNo == 3">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Services</h1>
                  <p class="pra">
                    We are currently checking your data. We are currently
                    checking your data The verification status will update
                    automatically. Great men knew this truth. Life to them was
                    action. This is the service of others. A life lived for
                    selfish gains, regardless of others needs, is worse than
                    death. A bird or a beast collects food for itself and its
                    young ones. It is some kind of work no doubt, but it is not
                    that kind of work that man is expected to do. He is the roof
                    and crown of creation and so he is supposed to live a larger
                    life by applying himself to the service of humanity.
                  </p>
                  <button
                    type="button"
                    class="btn btn-style btn-hori"
                    v-on:click="gotoPage('Services')"
                  >
                    Go to Services
                  </button>
                </div>
              </div>
              <div class="p4" v-if="stepNo == 4">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Stripe</h1>
                  <div class="row">
                      <div class="col text-center">
                      <img class="Stripe-img img-fluid" src="./../../assets/images/business_profile/Stripe_dollar2.svg"/>
                      </div>
                  </div>
                  <div class="row">
                      <p class="pra ms-5">
                        Secured and Fast Transaction Sign up or add your existing Stripe account <br> Ondaq payout daily
                      </p>
                      <button type="button" class="btn btn-style btn-hori" v-on:click="gotoPage('stripeConnect')" >
                        Go to Stripe
                      </button>
                  </div>
                </div>
              </div> -->
            </div>

            <!-- <div class="col-12 horiMultiStep">
              <h4 class="title mt-4">Complete Your Profile</h4>
              <ul>
                <li class="lineResponsive">
                  <span
                    class="circle"
                    v-if="stepNo == 1"
                    v-bind:class="stepNo < 2 ? 'bg-success' : ''"
                    >1</span
                  >
                  <span v-if="stepNo > 1">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="makeLine"></span>
                  <div class="font-18 mt-1">Business Profile</div>
                </li>
                <li class="lineResponsive">
                  <span
                    class="circle"
                    v-if="stepNo < 3"
                    v-bind:class="stepNo == 2 ? 'bg-success' : ''"
                    >2</span
                  >
                  <span v-if="stepNo > 2">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="makeLine"></span>
                  <div class="font-18 mt-1">Add Employee</div>
                </li>
                <li class="lineResponsive">
                  <span
                    class="circle"
                    v-if="stepNo < 4"
                    v-bind:class="stepNo == 3 ? 'bg-success' : ''"
                    >3</span
                  >
                  <span v-if="stepNo > 3">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <span class="makeLine"></span>
                  <div class="font-18 mt-1">Services</div>
                </li>
                <li style="text-align: center; min-width: 100px">
                  <span
                    class="circle"
                    v-if="stepNo < 5"
                    v-bind:class="stepNo == 4 ? 'bg-success' : ''"
                    >4</span
                  >
                  <span v-if="stepNo > 5">
                    <fa class="checkcircle" :icon="['fas', 'check-circle']" />
                  </span>
                  <div class="font-18 mt-1">Stripe</div>
                  <i class="fas fa-check-circle"></i>
                </li>
              </ul>

            </div> -->
            <!-- Horizontal RightSide -->
            <!-- <div class="col-12 horiRight">
              <div class="p1" v-if="stepNo == 1">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Business Profile</h1>
                   <div class="row">
                      <div class="col text-center">
                        <img class="businessProfile-img img-fluid" src="./../../assets/images/business_profile/business_profile.png"/>
                      </div>
                  </div>
                  <div class="row">
                    <p class="pra">
                      Carefully fill out your business details,schedules and manage your future dates.
                    </p>
                    <button type="button" class="btn btn-style btn-hori" v-on:click="gotoPage('editBusinessProfile')">
                        Go to Business-Profile
                    </button>
                  </div>
                </div>
              </div>
              <div class="p2" v-if="stepNo == 2">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Add Employee</h1>
                  <p class="pra">
                    The verification status will update automatically. We are
                    currently checking your data The verification status will
                    update automatically. Before candidates settle into your ad,
                    they’re first going to scan it. And if it’s not formatted
                    using big, bold, clear, and concise subheads to make the
                    scanning process effortless, they’ll move on.
                  </p>
                  <button
                    type="button"
                    class="btn btn-hori"
                    v-on:click="gotoPage('Employees')"
                  >
                    Go to Employee
                  </button>
                </div>
              </div>
              <div class="p3" v-if="stepNo == 3">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Services</h1>
                  <p class="pra">
                    We are currently checking your data. We are currently
                    checking your data The verification status will update
                    automatically. Great men knew this truth. Life to them was
                    action. This is the service of others. A life lived for
                    selfish gains, regardless of others needs, is worse than
                    death. A bird or a beast collects food for itself and its
                    young ones. It is some kind of work no doubt, but it is not
                    that kind of work that man is expected to do. He is the roof
                    and crown of creation and so he is supposed to live a larger
                    life by applying himself to the service of humanity.
                  </p>
                  <button
                    type="button"
                    class="btn btn-hori"
                    v-on:click="gotoPage('Services')"
                  >
                    Go to Services
                  </button>
                </div>
              </div>
              <div class="p4" v-if="stepNo == 4">
                <div class="para-hori">
                  <h1 class="pb-3 pt-3">Stripe</h1>
                  <div class="row">
                      <div class="col text-center">
                      <img class="Stripe-img img-fluid" src="./../../assets/images/business_profile/Stripe_dollar2.svg"/>
                      </div>
                  </div>
                  <div class="row">
                      <p class="pra">
                        Secured and Fast Transaction Sign up or add your existing Stripe account Ondaq payout daily
                      </p>
                      <button type="button" class="btn btn-style btn-hori col-12" v-on:click="gotoPage('stripeConnect')" >
                        Go to Stripe
                      </button>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- End Horizontal RightSide -->
          </div>
        </div>
      </div>
    </div>

    <!-- //Horizontal multistep form -->
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

export default {
  name: "ProfileCompletetion",

  created() {
    // console.log(this.$storage.getStorageSync("business"));
    if (this.$storage.getStorageSync("business").active_business.profile_completetion == 0) {
        this.getProfileStatus();
    }
    // $("#profileCompletetionModal").modal("show");
  },
  data() {
    return {
      stepNo: "",
      // name: "",
    };
  },
  methods: {
    gotoPage() {
      $("#profileCompletetionModal").modal("hide");
      // this.$router.push({ name: name });
    },
    getProfileStatus() {
      let thiss = this;
      axios
        .get("getProfileStatus/"+this.$storage.getStorageSync("business").active_business.id)
        .then(function (response) {
          if (response.data.status == "success") {
            let data = response.data.data;
            thiss.stepNo = data.step;
            // console.log(thiss.stepNo);
            if (data.status == "yes") {
              // alert(thiss.$route.name);
              if (
                thiss.$route.name !== "editBusinessProfile" &&
                thiss.$route.name !== "Employees" &&
                thiss.$route.name !== "Services" &&
                thiss.$route.name !== "addService" &&
                thiss.$route.name !== "addEmployee" &&
                thiss.$route.name !== "weeklySchedule" &&
                thiss.$route.name !== "stripeConnect"
              ) {
                $("#profileCompletetionModal").modal("show");
              }
            }else if(data.update_storage == "yes"){
              // TODO: Update vue3 storage
              // /this.$storage.getStorageSync("business").active_business.profile_completetion = 1
            }
            // console.log(thiss.employees);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
    },
  },
};
</script>

<style scoped>
.profile-completion-content h1{
  font-size: 32px;
  text-align: left;
}
.profile-completion-content img{
  width: 250px;
}
.profile-completion-content p {
  font-size: 18px;
  padding: 0 10px;
}
a.rem-btn{
  text-transform: none;
}
.font-18 {
  font-size: 18px;
}

.divHeight {
    height: 20px;
    border-left: 1px solid black;
    margin: 10px 24px;
}
.content {
  padding: 50px;
  background-color: #e7e9eb;
}
.circle {
  background-color: gray;
  color: white;
  margin-left: 7px;
  padding: 6px 12px;
  border-radius: 50%;
}
.checkcircle {
  font-size: 30px !important;
  color: #8c2390;
}
.horiMultiStep {
  display: none;
}
@media screen and (max-width: 1199px) {
  .profile-completion-content h1{
    font-size: 24px;
    text-align: center;
  }
  .profile-completion-content img{
    width: 200px;
  }
  .profile-completion-content p {
    font-size: 16px;
  }
  .lineResponsive {
    position: relative;
    text-align: center;
    min-width: 100px;
  }
  .makeLine {
    position: absolute;
    width: 100%;
    top: 22%;
    left: 77%;
    display: inline-block;
    border: 1px solid #8c2390;
  }
  .horiMultiStep {
    display: block;
  }
  .left-side {
    display: none;
  }
  h4 {
    font-size: 18px;
  }

  .font-18 {
    font-size: 14px;
  }

  .checkcircle {
    font-size: 22px !important;
  }
  .circle {
    font-size: 14px;
    padding: 5px 10px;
  }
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: 900;
  }
  ul {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
  }
}

@media screen and (max-width: 991px) {
  .lineResponsive {
    min-width: 90px;
  }
  .makeLine {
    position: absolute;
    width: 67%;
    left: 67%;
    display: inline-block;
    border: 1px solid #8c2390;
  }
}

@media screen and (max-width: 490px) {
  .lineResponsive {
    min-width: 65px;
  }
  .makeLine {
    position: absolute;
    width: 57%;
    left: 70%;
    display: inline-block;
    border: 1px solid #8c2390;
  }
}
@media screen and(max-width: 387px) {
  /* .lineResponsive {
    position: relative;
    text-align: center;
  } */
  .makeLine {
    position: absolute;
    width: 50%;
    left: 65%;
    top: 12%;
    display: inline-block;
    border: 1px solid #8c2390;
  }
}

</style>
